import { template as template_5ebcd9ec17d2425785f37d3811ef79c3 } from "@ember/template-compiler";
const FKText = template_5ebcd9ec17d2425785f37d3811ef79c3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
