import { template as template_9a8c88c50d6245ebacfb2bcc117d3e6c } from "@ember/template-compiler";
const FKControlMenuContainer = template_9a8c88c50d6245ebacfb2bcc117d3e6c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
