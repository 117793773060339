import { template as template_8b7f2866d928454abd9b89578003f3e4 } from "@ember/template-compiler";
const WelcomeHeader = template_8b7f2866d928454abd9b89578003f3e4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
