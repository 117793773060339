import { template as template_e62374bb349f4ae29619a94f8ff5a599 } from "@ember/template-compiler";
const FKLabel = template_e62374bb349f4ae29619a94f8ff5a599(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
